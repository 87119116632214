<mat-sidenav-container style="position: absolute; left: 0; right: 0px; top: 60px; bottom: 0px;">

    <mat-card style="overflow: hidden; position: absolute; right: 0px; top: 40vh; z-index: 200; width: 40px; height: 100px; padding: 0px;">

        <button mat-stroked-button color="primary" style="height: 100%; width: 90%; font-size: 1.5em; font-weight: 600; padding: 0px;" #drawerToggler (click)="devices.toggle()">

            <
        </button>

    </mat-card>

    <mat-sidenav (click)="devices.toggle()" #devices
                 class="sidenav"
                 fixedInViewport="false"
                 [opened]="showDevices"
                 position="end"
                 mode="over"
                 hasBackdrop="false"
                 style="width: 30%; ">

        <!-- <mat-toolbar>My Devices</mat-toolbar> -->
        <mat-nav-list (click)="$event.stopPropagation();">

            <mat-form-field style="width: 96%; margin:10px;" appearance="outline">
                <mat-label>Filter</mat-label>
                <input [(ngModel)]="criterion" #mail
                       matInput
                       type="mail" />
                <button (click)="criterion = ''"
                        mat-icon-button
                        matSuffix>
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>


            <mat-accordion>
                <div style="margin-top: -20px;"></div>
                <div *ngIf="parentOrg" style="padding: 5px;display: flex; align-content:flex-start; justify-content: flex-start; font-size: 1em;font-weight: 500; margin-bottom: 5px;">
                    <a>Parent: {{parentOrg.name}}</a>
                </div>
                <hr>
                <div sidebar-expansion-panel *ngFor="let org of userOrgData | filter:criterion; let i = index;">

                    <div *ngIf="i<2" style="padding: 5px;display: flex; align-content: flex-start; justify-content: flex-start; font-size: 1.6em;font-weight: 500; margin-bottom: 5px;">
                        <a *ngIf="i===0"><u>Home:</u></a>                    
                        <a *ngIf="i===1" style="margin-top: 25px;;"><u>Affiliations:</u></a>
                    </div>
                    <mat-expansion-panel style="margin: 5px;padding: 5px;">
                        <mat-expansion-panel-header>
                            <mat-panel-title [class]="themeManager.activeTheme + ' theme-fg-primary'">
                                <mat-icon color="accent" style="font-size: 1.4em;">room </mat-icon> <a style="font-weight: 500;font-size: 1.4em; letter-spacing: 2px;">{{(org.name.toUpperCase())}}</a>
                                <div style="position: absolute; right: 50px;">

                                    <mat-icon color="accent">nfc </mat-icon>
                                    <a style="position: absolute; margin-top: 3px; right:-10px">{{org.device_count}}</a>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>


                        <mat-accordion>
                            <div style="display: flex; align-content: center; justify-content: center; font-size: 1.6em;font-weight: 500; margin-bottom: 5px;">
                                <a><u>Branches</u></a>
                            </div>
                            <div sidebar-expansion-panel *ngFor="let branch of org.branches">

                                <mat-expansion-panel class="mat-elevation-z16">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title [class]="themeManager.activeTheme + ' theme-fg-primary'">
                                            <a style="font-weight: 500;font-size: 1.1em;"><u>{{(branch.name.toUpperCase())}} </u></a>
                                            <div style="position: absolute; right: 50px;">

                                                <mat-icon color="accent">nfc </mat-icon>
                                                <a style="position: absolute; margin-top: 3px; right:-10px">{{branch.devices.length}}</a>
                                            </div>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div style="display: flex; align-content: center; justify-content: center; font-size: 1.6em;font-weight: 500; margin-bottom: 5px;">
                                        <a><u>Devices</u></a>
                                    </div>

                                    <div *ngIf="branch.devices.length == 0" style="margin-left: 20px;">

                                        oops! this branch is empty.
                                    </div>

                                    <div style="flex-grow: 0.10; margin:10px; margin-bottom: 20px; padding: 5px; border: 1px solid grey;" *ngFor="let device of branch.devices">

                                        <app-device-card (deviceSelected)="onDeviceSelected($event);"
                                                         [Device]="device" (click)="devices.close()">
                                        </app-device-card>

                                    </div>


                                </mat-expansion-panel>

                            </div>

                        </mat-accordion>


                        <mat-action-row *ngIf="i>0">
                            <button mat-raised-button color="accent" style="float: left;">
                                <mat-icon color="warn">directions_run</mat-icon>
                                Leave Organization
                            </button>
                        </mat-action-row>

                    </mat-expansion-panel>

                </div>

            </mat-accordion>


            <!-- <div style="display:flex;flex-direction: column; justify-content: flex-end; ">

                <div style="flex-grow: 0.10; margin:10px" *ngFor="let device of userDevices | filter:criterion ">

                    <app-device-card (deviceSelected)="onDeviceSelected($event);"
                                     [device]="device">
                    </app-device-card>

                </div>

            </div> -->


        </mat-nav-list>
    </mat-sidenav>

    <div *ngIf="selectedDevice">

        <app-device-manager (addToDash)="onDeviceAddedToDash($event)"
                            [Device]="selectedDevice"
                            (closeManager)="onCloseDeviceManager()"
                            (refreshDevices)="fetchUserDevices()">
        </app-device-manager>
    </div>


    <div *ngIf="!hasDashItems" style="height: 100%; display:flex; justify-content: center; align-items: center;">
        <a style="font-size: 3em;">Your Dash Is Empty</a>

    </div>

    <div style="padding-left: 10px; padding-right: 20px;" #divvy *ngIf="hasDashItems">


        <ktd-grid style="margin: 0%; width: 100%; height: 100%;" [cols]="cols"
                  [rowHeight]="rowHeight"
                  [layout]="ktdGrid"
                  [compactType]="compType"
                  (layoutUpdated)="onLayoutUpdated($event)"
                  (resizeStarted)="onResizeStarted($event)"
                  (resizeEnded)="onResizeEnded($event)"
                  [scrollableParent]="null">
            <ktd-grid-item *ngFor="let dashItem of ktdGrid; trackBy:trackById"
                           [id]="dashItem?.id"
                           [transition]="'transform 200ms ease, width 200ms ease, height 200ms ease'">


                <ng-container *ngIf="getDashItemByID(dashItem.id).is_native">
                    <app-graph-card #graphCards *ngIf="(DashCharts.length > 0)"
                                    [userDashObj]="getDashItemByID(dashItem.id)"
                                    [dashGraphID]="dashItem.id"
                                    (removeFromDash)="onRemoveChartFromDash($event)"
                                    [onDash]="true">
                    </app-graph-card>

                </ng-container>


                <ng-container *ngIf="!getDashItemByID(dashItem.id).is_native">
                    <app-graph-card #graphCards *ngIf="(DashCharts.length > 0)"
                                    [userDashObj]="getDashItemByID(dashItem.id)"
                                    [dashGraphID]="dashItem.id"
                                    (removeFromDash)="onRemoveChartFromDash($event)"
                                    [onDash]="true">

                    </app-graph-card>
                </ng-container>

            </ktd-grid-item>

        </ktd-grid>

    </div>



</mat-sidenav-container>


<!--  -->