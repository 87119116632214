
import { Input, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppRootComponent } from '../app.component';
import '../app.censir.api.defs';
import { UserObj, UserDashObj } from '../app.censir.api.defs';
import { environment } from 'src/environments/environment';

export class SESSION {
  static title = 'Censir'
  static version = '2021.7 alpha';
  static isAuthed: boolean = false;
  static user: UserObj;
  static userInitials: string;
  static userFullName: string;

  static setUser(user: any): void {
    this.user = user;
    if (user.dash) {
       this.user.dash = user.dash.dash_json.items
     }
    this.isAuthed = true;

    if (environment.production) {
      this.version = '2021.11'
    }else{
      this.version = '*2021.11.DEV*'
    }

    this.setUserMoniker();
  }

  private static  setUserMoniker(): void {
    this.userInitials = this.user.name.substr(0, 1) + this.user.surname.substr(0, 1);
    this.userFullName = `${this.user.name} ${this.user.surname}`;
  }

}

export class JsonObject{

  static Copy(obj:any): any{
    return JSON.parse(JSON.stringify(obj));
  }

}



export class UnixDateFactory {

  //Will create a new date at current time if none is passed

  getNow(): number {
    let date = new Date();
    let unixStamp = this.dateToUnix(date);

    return (unixStamp);
  }

  getOffset(hourOffset: number = 0, dayOffset: number = 0, fromDate: Date = new Date): number {
    let unixStamp = this.dateToUnix(fromDate);

    return (unixStamp + this.unixDayOffset(dayOffset)) + this.unixHourOffset(hourOffset);
  }

  unixToDate(unixStamp: number): Date {
    return new Date(unixStamp * 1000);
  }

  dateToUnix(date: Date): number {
    return Math.floor(date.getTime() / 1000);
  }

  getUUIDv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }



  private unixHourOffset(hrs: number): number {
    return Math.floor(hrs * 60 * 60);
  }

  private unixDayOffset(days: number): number {
    return Math.floor(days * 24 * 60 * 60)
  }

}



@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}



@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {


  transform(data: any, criterion: any): any {
    if (criterion === undefined || data === undefined) {
      return data;
    }

    console.log(criterion);
    return data.filter((set) => {
      return set.name.toLowerCase().includes(criterion.toLowerCase());
    });

  }

}
