import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { error } from '@angular/compiler/src/util';


import { environment } from 'src/environments/environment';

export enum CallType {
  GET = 0,
  POST = 1,
  DELETE = 2,
  PUT = 3
}



@Injectable({
  providedIn: 'root'
})
export class APICaller {

  // set host url here
  hostUrl = '';
  devUrl = '';
  prodUrl = '';
  influxURL = '';
  isProduction: boolean = false;

  constructor(private http: HttpClient) {

    if (environment.production) {
      this.isProduction = true;
      this.hostUrl = 'https://www.censir.co.za';
      this.influxURL = 'https://influx.censir.co.za';
      //cross links
      this.devUrl = 'https://www.uat.censir.co.za';
      this.prodUrl = 'https://www.censir.co.za';
      console.log(this.hostUrl, "Production Mode Enabled");
    } else {
      // this.hostUrl = 'http://uat.censir.co.za';
      this.hostUrl = 'https://www.uat.censir.co.za';
      this.influxURL = 'https://influx.uat.censir.co.za';
      //cross links
      this.devUrl = 'https://www.uat.censir.co.za';
      this.prodUrl = 'https://www.censir.co.za';
      console.log(this.hostUrl, "Development Mode Enabled!");
    }
    
  }

  private PrepHeaderPayload(): HttpHeaders {
    let headers: HttpHeaders;

    // if (sessionVars.loginToken) {
    headers = new HttpHeaders({
      'Content-Type': 'application/json',
      //     'Authorization': 'Token ' + sessionVars.loginToken
    });
    return headers;
    // };
    return headers;
  }

  // generic post function to send pre-made forms to a endpoint
  PostFromData<T>(endpoint: string, form: FormData, callResponse?: (r: T) => void, callError?: (e: T) => void): void {
    const headers = this.PrepHeaderPayload();

    //REMEMBER TO CREATE A DIFFERENT PAYLOAD FOR FORM POSTS, THE NORMAL HEADER PAYLOAD WONT WORK
    // this.http.post<T>(`${this.hostUrl}${endpoint}`, form, { headers, withCredentials: true }).subscribe(formResponse => {
    //   callResponse?.(formResponse);
    // }, formError => {
    //   callError?.(formError);
    // });

  }


  CallEndPoint<T, Te>(endpoint: string, body: any = null, calltype: CallType, callResponse?: (r: T) => void, callError?: (e: Te) => void): void {

    const headers = this.PrepHeaderPayload();

    if (calltype === CallType.GET) {

      this.http.get<T>(`${this.hostUrl}${endpoint}`, { headers, withCredentials: true }).subscribe(getResponse => {
        callResponse?.(getResponse);
      }, getError => {
        callError?.(getError);
      });

    } else if (calltype === CallType.POST) {

      this.http.post<T>(`${this.hostUrl}${endpoint}`, body, { headers, withCredentials: true }).subscribe(postResponse => {
        callResponse?.(postResponse);
      }, postError => {
        callError?.(postError);
      });

    } else if (calltype === CallType.DELETE) {

      this.http.delete<T>(`${this.hostUrl}${endpoint}`, { headers, withCredentials: true }).subscribe(deleteResponse => {
        callResponse?.(deleteResponse);
      }, deleteError => {
        callError?.(deleteError);
      });

    } else if (calltype === CallType.PUT) {

      this.http.put<T>(`${this.hostUrl}${endpoint}`, body, { headers, withCredentials: true }).subscribe(putResponse => {
        callResponse?.(putResponse);
      }, putError => {
        callError?.(putError);
      });

    }
  }
  

  CallRawEndPoint<T, Te>(endpoint: string, body: any = null, calltype: CallType, callResponse?: (r: T) => void, callError?: (e: Te) => void): void {

    const headers = this.PrepHeaderPayload();

    if (calltype === CallType.GET) {

      this.http.get<T>(`${endpoint}`, { headers, withCredentials: true }).subscribe(getResponse => {
        callResponse?.(getResponse);
      }, getError => {
        callError?.(getError);
      });

    } else if (calltype === CallType.POST) {

      this.http.post<T>(`${endpoint}`, body, { headers, withCredentials: true }).subscribe(postResponse => {
        callResponse?.(postResponse);
      }, postError => {
        callError?.(postError);
      });

    } else if (calltype === CallType.DELETE) {

      this.http.delete<T>(`${endpoint}`, { headers, withCredentials: true }).subscribe(deleteResponse => {
        callResponse?.(deleteResponse);
      }, deleteError => {
        callError?.(deleteError);
      });

    } else if (calltype === CallType.PUT) {

      this.http.put<T>(`${endpoint}`, body, { headers, withCredentials: true }).subscribe(putResponse => {
        callResponse?.(putResponse);
      }, putError => {
        callError?.(putError);
      });

    }
  }

}




