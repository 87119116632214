<mat-card *ngIf="Device" class='mat-elevation-z16' configMode>
    <mat-card-title [class]="thememan.activeTheme + ' theme-bg-primary ' + thememan.activeTheme + ' theme-fg-accent'" style="height:38px;">
        <button mat-mini-fab aria-label="Example icon button with a home icon" style="float:right; z-index: 10;" (click)="closeMe();">
            <mat-icon>close</mat-icon>
        </button>
        <div style="height: 10px;"></div>
        <a><b>&nbsp; Active Device: </b>{{Device.soft_name}}</a>

    </mat-card-title>
    <mat-card-subtitle>
        <a>{{Device.address}} </a>
        <a><b [class]="thememan.activeTheme + ' theme-fg-accent'">Device:</b> {{Device.hard_name}} <b [class]="thememan.activeTheme + ' theme-fg-accent'">Topics:</b> {{deviceCharts?.length}} </a>
        <a><b [class]="thememan.activeTheme + ' theme-fg-accent'">Created:</b> {{Device.in_date}} </a>
        <br>

    </mat-card-subtitle>

    <hr>
    <mat-card-content>
        <div *ngIf="Device">


            <mat-tab-group mat-align-tabs="center" dynamicHeight tab-panel>

                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon">nfc</mat-icon>
                        Topics
                    </ng-template>

                    <div style="display: flex; padding: 20px; padding-bottom: 20px;">

                        <mat-card class="mat-elevation-z12" style="margin-right: 50px; flex-grow:1; max-width: 55%;width:55% ">

                            <div style="width:100%; font-size: 2em; margin-bottom:0px; padding-bottom: 0px;"><a style="margin-left: 55%;">Topics</a></div>
                            <hr>

                            <!-- [disabled]="(selectedTopic != null)" -->
                            <mat-list dense mat-list scroll-on-overflow style="width:100%; height: 400px" role="list">

                                <mat-list-item *ngFor="let topic of Device.topics" role="listitem">
                                    <div style="font-size: 25px; line-height: 25px; width: 100%; display: flex; flex-direction: row; ">

                                        <button mat-stroked-button
                                                style="width:85%;text-align: left; flex-grow: 1; height: 30;"
                                                [class]="topic.ingest_active ? thememan.activeTheme + ' theme-fg-accent' : thememan.activeTheme + ' theme-fg-warn' "
                                                (click)="onTopicSelected(topic)">



                                            <mat-icon class=" example-tab-icon">nfc</mat-icon>
                                            <a>{{topic.soft_name}} </a><a *ngIf="topic.si_unit?.length > 0">({{topic.si_unit}})</a>



                                            <a style="position: absolute; left: 90%;">
                                                <a style="color:gray">Active:&nbsp;</a>
                                                <a>{{topic.ingest_active}}</a>
                                            </a>

                                            <a style="position: absolute; left: 75%;">
                                                <a style="color:gray;">Target:&nbsp;</a>
                                                <a *ngIf="topic.ingest_destination == 1">MySQL</a>
                                                <a *ngIf="topic.ingest_destination == 2">Influx</a>
                                                <a *ngIf="topic.ingest_destination == 3">Ignored</a>
                                            </a>

                                            <a style="position: absolute; left: 40%;">
                                                <a style="color:gray">Hard name:&nbsp;</a>
                                                <a>{{topic.hard_name}}</a>
                                            </a>

                                        </button>
                                        <!-- 
                                        <button mat-stroked-button color="warn"
                                                style="margin-left: 10px; flex-grow: 0.1;" aria-label="Example icon button with a home icon">
                                            <mat-icon>delete</mat-icon>
                                        </button> -->
                                    </div>
                                </mat-list-item>

                            </mat-list>

                        </mat-card>

                        <mat-card *ngIf="selectedTopic" class="mat-elevation-z12" style="flex-grow:1; width:43%">
                            <div style="width:100%; font-size: 2em; margin-bottom:0px; padding-bottom: 0px;"><a style="margin-left: 45%;">Topic: {{selectedTopic.hard_name}}</a></div>
                            <hr>

                            <div style="display: flex; flex-direction: column; justify-content: space-between; padding-top: 20px; padding-left:15px; padding-right: 15px;">

                                <div>
                                    <mat-form-field style="width: 100%;" appearance="outline">
                                        <mat-label>Soft Name</mat-label>
                                        <input [(ngModel)]="selectedTopic.soft_name" autocomplete="off"
                                               matInput
                                               type="text" />
                                    </mat-form-field>

                                    <mat-form-field style="width: 100%;" appearance="outline">
                                        <mat-label>SI Unit</mat-label>
                                        <input [(ngModel)]="selectedTopic.si_unit" autocomplete="off"
                                               matInput
                                               type="text" />
                                    </mat-form-field>

                                </div>

                                <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; ">

                                    <mat-form-field class="dynaform-row-item" appearance="outline" style="flex-grow: 1;  margin-right: 15px;">
                                        <mat-label>Topic Ingress To</mat-label>
                                        <mat-select [(ngModel)]="selectedTopic.ingest_destination">
                                            <mat-option [value]="3"> Ignore </mat-option>
                                            <mat-option [value]="2"> Influx DB </mat-option>
                                            <mat-option [value]="1"> MySQL DB </mat-option>
                                        </mat-select>

                                    </mat-form-field>

                                    <mat-form-field class="dynaform-row-item" appearance="outline" style="flex-grow: 1; ">
                                        <mat-label>Topic State</mat-label>
                                        <mat-select [(ngModel)]="selectedTopic.ingest_active">
                                            <mat-option [value]="true"> Enabled </mat-option>
                                            <mat-option [value]="false"> Disabled </mat-option>
                                        </mat-select>

                                    </mat-form-field>


                                </div>

                                <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; ">

                                    <mat-form-field style="width: 100%; margin-right: 15px;" appearance="outline">
                                        <mat-label>Soft Name For Bool True</mat-label>
                                        <input [(ngModel)]="selectedTopic.bool_true" autocomplete="off"
                                               matInput
                                               type="text" />
                                    </mat-form-field>


                                    <mat-form-field style="width: 100%;" appearance="outline">
                                        <mat-label>Soft Name For Bool False</mat-label>
                                        <input [(ngModel)]="selectedTopic.bool_false" autocomplete="off"
                                               matInput
                                               type="text" />
                                    </mat-form-field>

                                </div>

                            </div>

                            <div style="position: absolute; bottom: 15px;left: 0px; right:0px; display: flex; flex-direction: row; justify-content: space-between; padding-top: 20px; padding-left:15px; padding-right: 15px;">

                                <button mat-raised-button color="warn" style="width:40%; "
                                        aria-label="Example icon button with a home icon" (click)="selectedTopic = null">
                                    <mat-icon ico-margin>cancel</mat-icon>Close
                                </button>

                                <button mat-raised-button color="accent" style=" width:40%;"
                                        aria-label="Example icon button with a home icon" (click)="onTopicSave()">
                                    <mat-icon ico-margin>save</mat-icon>Save & Close
                                </button>


                            </div>
                        </mat-card>


                    </div>

                </mat-tab>



                <mat-tab>
                    <ng-template mat-tab-label (click)="fetchInputDefinitions();">
                        <mat-icon class="example-tab-icon">insights</mat-icon>
                        Links
                    </ng-template>

                    <div style="display: flex; padding: 20px; padding-bottom: 20px;">

                        <mat-card class="mat-elevation-z12" style="width:100%; max-height: 500px; overflow-y: scroll; ">
                            <button mat-mini-fab aria-label="Example icon button with a home icon" style="float:right; z-index: 10;" (click)="addNewLink();">
                                <mat-icon>add_circle_outline</mat-icon>
                            </button>
                            <div style="width:100%; font-size: 2em; margin-bottom:0px; padding-bottom: 0px;"><a style="margin-left: 45%;">Links</a></div>
                            <hr>

                            <mat-accordion>


                                <mat-expansion-panel style="width: 100%; margin-bottom: 10px; " *ngFor="let iframe of deviceiFrames" role="listitem">

                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            {{iframe.name}} - {{iframe.note}}
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div style="margin-bottom: 10px; padding: 10px;">
                                        <mat-form-field style="width: 100%" appearance="outline">
                                            <mat-label>url</mat-label>
                                            <input [(ngModel)]="iframe.url" autocomplete="off"
                                                   matInput
                                                   type="text" />
                                        </mat-form-field>

                                        <div style="display: flex; flex-direction: row; ">

                                            <mat-form-field style="width: 100%; margin-right: 10px;" appearance="outline">
                                                <mat-label>Name</mat-label>
                                                <input [(ngModel)]="iframe.name" autocomplete="off"
                                                       matInput
                                                       type="text" />
                                            </mat-form-field>

                                            <mat-form-field style="width: 100%;" appearance="outline">
                                                <mat-label>Note</mat-label>
                                                <input [(ngModel)]="iframe.note" autocomplete="off"
                                                       matInput
                                                       type="text" />
                                            </mat-form-field>

                                        </div>

                                        <button mat-raised-button color="warn" style=" width:20%;margin-left: 20% ;"
                                                aria-label="Example icon button with a home icon" (click)="deleteLink(iframe)">
                                            <mat-icon ico-margin>delete_outline</mat-icon>Delete
                                        </button>
                                        <button mat-raised-button color="accent" style=" width:20%; "
                                                aria-label="Example icon button with a home icon" (click)="updateLink(iframe)">
                                            <mat-icon ico-margin>save</mat-icon>Save
                                        </button>
                                        <button mat-raised-button color="accent" style=" width:20%;"
                                                aria-label="Example icon button with a home icon" (click)="callAddIframeToDash(iframe)">
                                            <mat-icon ico-margin>add_circle_outline</mat-icon>Add To Dash
                                        </button>

                                    </div>
                                </mat-expansion-panel>

                            </mat-accordion>

                        </mat-card>

                    </div>

                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label (click)="fetchInputDefinitions();">
                        <mat-icon class="example-tab-icon">touch_app</mat-icon>
                        Commands
                    </ng-template>


                    <div style="display: flex; padding: 20px; padding-bottom: 20px;">

                        <mat-card class="mat-elevation-z12" style="width:100%; max-height: 500px; overflow-y: scroll; ">
                            <button mat-mini-fab aria-label="Example icon button with a home icon" style="float:right; z-index: 10;" (click)="addNewCommands();">
                                <mat-icon>add_circle_outline</mat-icon>
                            </button>
                            <div style="width:100%; font-size: 2em; margin-bottom:0px; padding-bottom: 0px;"><a style="margin-left: 45%;">Commands</a></div>
                            <hr>

                            <mat-accordion>

                                <mat-expansion-panel style="width: 100%; margin-bottom: 10px; " *ngFor="let cmd of deviceCmds" role="listitem">

                                    <mat-expansion-panel-header>
                                        <mat-panel-title>

                                            <div btn-div>

                                                <a style="line-height: 45px;"><b>{{cmd.device.hard_name}}/sys/{{cmd.topic}}/{{cmd.command}}</b></a>

                                            </div>

                                        </mat-panel-title>


                                        <mat-panel-description style="position: relative; width: 50%;">


                                            <div btn-div style="position: absolute; right:415px;">

                                                <a style="line-height: 45px;">Ack:</a>
                                                <a style="line-height: 45px;">{{cmd?.ack}}</a>

                                            </div>

                                            <div btn-div style="position: absolute; right:210px;">

                                                <a style="line-height: 32px;">Val:</a>
                                                <input [(ngModel)]="cmd.value" autocomplete="off"
                                                       custom-input
                                                       matInput
                                                       (click)="$event.stopPropagation();"
                                                       type="text" />
                                            </div>

                                            <div btn-div style="position: absolute; right:5px;">
                                                <button mat-stroked-button color="accent"
                                                        aria-label="Example icon button with a home icon" (click)="$event.stopPropagation(); sendCommandToHopper(cmd)">
                                                    <mat-icon ico-margin>send</mat-icon>Send Command
                                                </button>
                                            </div>

                                        </mat-panel-description>

                                    </mat-expansion-panel-header>

                                    <div style="margin-bottom: 10px; padding: 10px;">

                                        <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-evenly; align-content: space-around; " >
                                            <div style="display: flex; flex-direction: row; width: 40%; justify-content: center; align-content: center;" >
                                                <a style="line-height: 45px; font-size: 1em;"><b>Last Send: </b></a>&nbsp;
                                                <a style="line-height: 45px;">{{datePipe.transform(cmd?.cmd_date, 'YYYY-MM-dd hh:mm:ss a')}}</a>
                                            </div>


                                            <div style="display: flex; flex-direction: row; float:right; width: 40%; justify-content: center; align-content: center; " >
                                                <a style="line-height: 45px; font-size: 1em;"><b>Last Ack: </b></a>&nbsp;
                                                <a style="line-height: 45px;">{{datePipe.transform(cmd?.ack_date, 'YYYY-MM-dd hh:mm:ss a')}}</a>
                                            </div>
                                        </div>
                                        <div style="display: flex; flex-direction: row; ">
                                            <mat-form-field style="width: 100%; margin-right: 10px;" appearance="outline">
                                                <mat-label>Device</mat-label>
                                                <input [(ngModel)]="cmd.device.hard_name" autocomplete="off"
                                                       matInput
                                                       type="text" />
                                            </mat-form-field>

                                            <mat-form-field style="width: 100%; margin-right: 10px;" appearance="outline">
                                                <mat-label>Topic</mat-label>
                                                <input [(ngModel)]="cmd.topic" autocomplete="off"
                                                       matInput
                                                       [disabled]="true"
                                                       type="text" />
                                            </mat-form-field>

                                            <mat-form-field style="width: 100%; margin-right: 10px;" appearance="outline">
                                                <mat-label>Command</mat-label>
                                                <input [(ngModel)]="cmd.command" autocomplete="off"
                                                       matInput
                                                       type="text" />
                                            </mat-form-field>

                                            <mat-form-field style="width: 100%;" appearance="outline">
                                                <mat-label>Value</mat-label>
                                                <input [(ngModel)]="cmd.value" autocomplete="off"
                                                       matInput
                                                       type="text" />
                                            </mat-form-field>


                                        </div>

                                        <button mat-raised-button color="warn" style=" width:20%;margin-left: 20% ;"
                                                aria-label="Example icon button with a home icon" (click)="deleteCommands(cmd)">
                                            <mat-icon ico-margin>delete_outline</mat-icon>Delete
                                        </button>
                                        <button mat-raised-button color="accent" style=" width:20%; "
                                                aria-label="Example icon button with a home icon" (click)="updateCommands(cmd)">
                                            <mat-icon ico-margin>save</mat-icon>Save
                                        </button>
                                        <button mat-raised-button color="accent" style=" width:20%;"
                                                aria-label="Example icon button with a home icon" (click)="sendCommandToHopper(cmd)">
                                            <mat-icon ico-margin>send</mat-icon>Send Command
                                        </button>

                                    </div>
                                </mat-expansion-panel>

                            </mat-accordion>

                        </mat-card>

                    </div>

                </mat-tab>

            </mat-tab-group>

        </div>

    </mat-card-content>

</mat-card>