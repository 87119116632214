import { Component, OnInit } from '@angular/core';
import { APICaller, CallType } from 'src/app/app.censir.api_caller';

import '../../../app.censir.api.defs';
//import { OfficeObj } from '../../../app.censir.api.defs';
import { SESSION } from '../../app.global-manager';

// Generated by https://quicktype.io
import { Router } from '@angular/router';

import { ThemeManager } from '../../app.theme-manager';


@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {
  thememan: ThemeManager;
 // userOffice: OfficeObj;
  constructor(private apicaller: APICaller, private router: Router) { }

  themezs = '';
  ngOnInit(): void {

    if (SESSION.isAuthed) {
      this.router.navigate(['login']);
    }

    this.thememan = ThemeManager.themeManager;
    //this.fetchUserOffice();
  }


  // fetchUserOffice(): void {
  //   this.apicaller.CallEndPoint<any, any>('/api/user_office', {}, CallType.GET, (r) => {
  //     // console.log(r);
  //     this.userOffice = r.data;
  //   }, (e) => {
  //     console.log(e.error.detail);
  //     // this.toast.open('invalid credentials', 'got it!', { duration: 3000 });
  //   });

  // }




}
