<mat-card [ngStyle]="{'height': onDash ? '100%' : '280px'}" style="margin-right: 5px; margin-top: 5px;" class="mat-elevation-z8">

  <div id="graph-card" [class]="thememan.activeTheme + ' theme-fg-accent '">

  </div>
  <!-- <mat-card-title [class]="thememan.activeTheme + ' theme-bg-primary '" style="height:32px;">

  </mat-card-title> -->

  <!-- <button mat-mini-icon-button [matMenuTriggerFor]="rowMenu"
          [matMenuTriggerData]="{}"
          style="position:absolute;right:0px; z-index: 10; top:0px;">
        </button> -->

  <mat-icon color="warn" [matMenuTriggerFor]="rowMenu"
            [matMenuTriggerData]="{}"
            style="position:absolute;right:0px; z-index: 10; top:0px; cursor:pointer;">more_horiz</mat-icon>

  <div style="position:absolute; font-size: 22px; font-weight: 500; height: 20px; margin-top: -18px; right:25px; left: 25px; overflow:hidden; cursor: move;">
    <!-- <a device-title>&nbsp; {{nativeChartObj.topic.ingest_destination}} {{nativeChartObj.topic.soft_name}} ( {{curval}} {{nativeChartObj.topic.si_unit}})</a> -->
    <a device-title>&nbsp; {{userDashObj.external_chart_name}} - {{userDashObj.external_chart_note}}</a>
  </div>
  <!-- <div style="position:absolute;font-size: 20px;font-weight: 100; height: 18px; margin-top: 10px; width:70%; overflow:hidden">
    <a device-title>&nbsp; {{nativeChartObj.topic.soft_name}} ( {{curval}} {{nativeChartObj.topic.si_unit}})</a>
  </div> -->

  <div style="border: 1px dashed grey; border-radius: 0px; position: absolute; left:5px; right:5px;bottom:43px;top:22px;">


    <!-- <mat-slider #middlescale thumbLabel [displayWith]="formatLabel" vertical min="0" tickInterval="5" max="25" step="2" value="0" style="width:10%" matTooltip="chart middle-out scale" (change)="scaleChanged()"></mat-slider> -->
    <!-- [ngStyle]="{'height': maximized ? '80vh' : '240px'}" -->
    <!-- <div  [ngStyle]="{'height': onDash ? '100%' : '240px'}">

    </div> -->
    <!-- <div devframe style="position:absolute; left:10px;right:10px;bottom:10px;top:50px" *ngTemplateOutlet="chartTemplate">
      </div> -->


    <div *ngIf="userDashObj.is_native" style="position:absolute; left:2px;right:10px;bottom:2px;top:2px">

      <ngx-charts-line-chart #chart
                             [class]="thememan.activeTheme + ' ngx-charts'"
                             [results]="chartData"
                             [xAxis]="true"
                             [yAxis]="true"
                             [animations]="false"
                             [timeline]="showTimeline"
                             [autoScale]="autoScale"
                             [scheme]="colorScheme"
                             [xAxisLabel]="xAxisLabel"
                             [yAxisLabel]="yAxisLabel"
                             [showXAxisLabel]="showXAxisLabel"
                             [showYAxisLabel]="showYAxisLabel"
                             [xAxisTickFormatting]='formatPercent'
                             [showGridLines]="showGridLines">

        <ng-template #tooltipTemplate let-model="model">
          {{makeDate(model?.name)}}
          <br>
          <a [ngStyle]="{'color':colorScheme.domain[0]}">⦿ {{model?.series}} ⦿</a>
          <br>
          {{model.value}} {{nativeChartObj.topic?.si_unit}}
        </ng-template>

        <ng-template #seriesTooltipTemplate let-model="model">
          {{makeDate(model[0]?.name)}}
          <br>
          <a [ngStyle]="{'color':colorScheme.domain[0]}">⦿ {{model[0]?.series}} ⦿</a>
          <br>
          {{model[0]?.value}} {{nativeChartObj?.topic.si_unit}}
        </ng-template>

      </ngx-charts-line-chart>
    </div>



    <iframe
            *ngIf="!userDashObj.is_native"
            #frame
            [id]="userDashObj.grid_location.id"
            style="height: 100%; width: 100%;"



            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox allow-presentation"
            allowtransparency="true"
            allowfullscreen>

    </iframe>



  </div>

</mat-card>










<!--ROW MENU:-->
<mat-menu #rowMenu="matMenu">
  <ng-template matMenuContent let-sectionIndex="iSection" let-rowIndex="iRow">

    <div *ngIf="userDashObj.is_native">


      <button *ngIf="onDash"
              mat-menu-item
              [matMenuTriggerFor]="fieldMenu"
              [matMenuTriggerData]="{}">
        <mat-icon color="accent">schedule</mat-icon>
        Time Frame
      </button>




      <div *ngIf="onDash " mat-menu-item>

        <mat-slide-toggle (click)="$event.stopPropagation();"
                          matTooltip="Toggle Grid"
                          [(ngModel)]="showTimeline">Timeline

        </mat-slide-toggle>
      </div>


      <div mat-menu-item>

        <mat-slide-toggle (click)="$event.stopPropagation();"
                          matTooltip="Toggle Grid"
                          [(ngModel)]="showGridLines">Grid

        </mat-slide-toggle>
      </div>


      <div mat-menu-item>

        <mat-slide-toggle (click)="$event.stopPropagation();"
                          matTooltip="Toggle Grid"
                          [(ngModel)]="autoScale">AutoScale

        </mat-slide-toggle>
      </div>


      <button *ngIf="!onDash"
              mat-menu-item
              matTooltip="Add To Dashboard"
              (click)="callAddToDash();">
        <mat-icon color="accent">dashboard_customize</mat-icon>
        Add To Dash
      </button>


      <button *ngIf="onDash"
              mat-menu-item
              matTooltip="Remove From Dash"
              (click)="callRemoveFromDash();">
        <mat-icon color="warn">close</mat-icon>
        Remove Graph
      </button>
    </div>

    <button *ngIf="onDash && !userDashObj.is_native"
            mat-menu-item
            matTooltip="Remove From Dash"
            (click)="callRemoveFromDash();">
      <mat-icon color="warn">close</mat-icon>
      Remove Link
    </button>

  </ng-template>
</mat-menu>


<!--FIELD MENUS:-->
<mat-menu #fieldMenu="matMenu">
  <div style="width: 240px; height: 400px; position: relative;">
    <ng-template matMenuContent let-sectionIndex="iSection" let-rowIndex="iRow" let-fieldIndex="iField">
      <mat-card field-menu (click)="$event.stopPropagation();">

        <mat-form-field appearance="fill">
          <mat-label>Start</mat-label>
          <mat-select [(ngModel)]="influxStart" (selectionChange)="fetchSeries();">
            <mat-option value="-5m">-5m</mat-option>
            <mat-option value="-10m">-10m</mat-option>
            <mat-option value="-30m">-30m</mat-option>
            <mat-option value="-1h">-1h</mat-option>
            <mat-option value="-3h">-3h</mat-option>
            <mat-option value="-6h">-6h</mat-option>
            <mat-option value="-12h">-12h</mat-option>
            <mat-option value="-1d">-1d</mat-option>
            <mat-option value="-2d">-2d</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Intervals</mat-label>
          <mat-select [(ngModel)]="influxWindow" (selectionChange)="fetchSeries();">
            <mat-option value="5s">5s</mat-option>
            <mat-option value="10s">10s</mat-option>
            <mat-option value="30s">30s</mat-option>
            <mat-option value="1m">1m</mat-option>
            <mat-option value="5m">5m</mat-option>
            <mat-option value="10m">10m</mat-option>
          </mat-select>
        </mat-form-field>
        custom dates are *** WIP ***
        above only works on influx for now
      </mat-card>
    </ng-template>
  </div>
</mat-menu>


<!-- TEMPLATE FOR THE GRAPH.. not really needed seperately anymore but does no harm to have it seperate -->
<!-- <ng-template #chartTemplate>

  <ngx-charts-line-chart #chart
                         [class]="thememan.activeTheme + ' ngx-charts'"
                         [results]="chartData"
                         [xAxis]="true"
                         [yAxis]="true"
                         [animations]="false"
                         [timeline]="showTimeline"
                         [autoScale]="autoScale"
                         [scheme]="colorScheme"
                         [xAxisLabel]="xAxisLabel"
                         [yAxisLabel]="yAxisLabel"
                         [showXAxisLabel]="showXAxisLabel"
                         [showYAxisLabel]="showYAxisLabel"
                         [xAxisTickFormatting]='formatPercent'
                         [showGridLines]="showGridLines">

    <ng-template #tooltipTemplate let-model="model">
      {{makeDate(model.name)}}
      <br>
      <a [ngStyle]="{'color':colorScheme.domain[0]}">⦿ {{model.series}} ⦿</a>
      <br>
      {{model.value}} {{inputDefinition.input_si_unit}}
    </ng-template>

    <ng-template #seriesTooltipTemplate let-model="model">
      {{makeDate(model[0].name)}}
      <br>
      <a [ngStyle]="{'color':colorScheme.domain[0]}">⦿ {{model[0].series}} ⦿</a>
      <br>
      {{model[0].value}} {{inputDefinition.input_si_unit}}
    </ng-template>

  </ngx-charts-line-chart>

</ng-template> -->