import { Component, HostBinding, OnInit, Input, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';


import { AppRootComponent } from 'src/app/app.component';
import { APICaller, CallType } from '../../../app.censir.api_caller';
import { ThemeManager } from '../../app.theme-manager';
import { SESSION } from '../../app.global-manager';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  themeManager: ThemeManager;

  username:string;
  password:string;
  isAuthed:boolean;

  hidePass = true;
  canShowPass = false; // this makes user unable to see pre saved passwords until the password box was cleared
  mySession = SESSION;

  constructor(private router: Router, private apicaller: APICaller, private toast: MatSnackBar) {

    console.log('using host: ' + apicaller.hostUrl);
  
  }

  ngOnInit(){
    
  }


  autofilled(event): void {
    console.log(event);

    if (event.isAutofilled) {
      this.hidePass = true;
      this.canShowPass = false;
      return;
    }

    if (!event.isAutoFilled && String(this.password).length > 0) {
      this.canShowPass = false;
      this.hidePass = true;
    } else {
      this.canShowPass = true;
    }

    if (String(this.password).length === 0) {
      this.canShowPass = true;
    }
  }


  passChanged(): void {
    if (String(this.password).length === 0) {
      this.canShowPass = true;
    }
  }


  login(): void {
    AppRootComponent.appRootComponent.authUser(this.username,this.password);
  }


  logout(): void {
    AppRootComponent.appRootComponent.deauthUser();
    this.isAuthed = false;
  }

}


