import { Component, OnInit, ViewChild, ViewChildren, QueryList, } from '@angular/core';
import { Router } from '@angular/router';
import { APICaller, CallType } from '../../../app.censir.api_caller';

import { SESSION, UnixDateFactory } from '../../app.global-manager';
import { DeviceObj, UserDashObj, NativeChartObj, LineChartObj, UserOrgRelationObj } from '../../../app.censir.api.defs';
import { ThemeManager } from '../../app.theme-manager';
import { ViewportRuler } from "@angular/cdk/overlay";
import { ktdTrackById, KtdGridLayout, KtdGridCompactType } from '@katoid/angular-grid-layout';

import {
  CdkDrag, CdkDragDrop,
  CdkDragStart,
  CdkDropList, CdkDropListGroup, CdkDragMove, CdkDragEnter,
  moveItemInArray
} from "@angular/cdk/drag-drop";
import { MatYearView } from '@angular/material/datepicker';
import { DeviceManagerComponent } from '../../widgets/device-manager/device-manager.component';
import { GraphCardComponent } from '../../widgets/graph-card/graph-card.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

  @ViewChild(DeviceManagerComponent) deviceManager: DeviceManagerComponent;

  @ViewChildren('graphCards') graphCards: QueryList<GraphCardComponent>;

  //internal
  themeManager: ThemeManager;
  mySession = SESSION;
  criterion: string;
  unixDates = new UnixDateFactory();

  selectedDevice: DeviceObj;

  curLineObj: LineChartObj;


  compType: KtdGridCompactType = 'vertical';
  cols: number = 16;
  rowHeight: number = 100;

  ktdGrid: KtdGridLayout = [];
  trackById = ktdTrackById

  hasDashItems: boolean = false;

  showDevices = false;


  //incoming
  userOrgData: UserOrgRelationObj[];
  selectedDeviceNativeCharts: NativeChartObj[];
  DashCharts: UserDashObj[] = new Array();
  detailViewChart: DeviceObj;
  parentOrg: any;


  //outgoing

  constructor(private router: Router, private apicaller: APICaller, private viewportRuler: ViewportRuler) {
    this.themeManager = ThemeManager.themeManager;
    if ((SESSION.user?.dash ?? null)) {
      this.hasDashItems = true;
    }
  }

  setGrav(e) {
    this.compType = e;
    console.log(e);
  }

  ngOnInit(): void {

    if (!SESSION.isAuthed) {
      this.router.navigate(['login']);
      return;
    }
    this.fetchUserParentOrg();
    this.fetchUserDevices();
  }



  drop(event: CdkDragDrop<DeviceObj[]>) {

    console.log(this.DashCharts);
    moveItemInArray(this.DashCharts, event.previousIndex, event.currentIndex);

  }

  fetchUserParentOrg(): void {

    this.apicaller.CallEndPoint<any, any>('/api/parent_organization', {}, CallType.GET, (r) => {

      this.parentOrg = r[0];
      console.log(r);

    }, (e) => {
      console.log(e.error.detail);
    });

  }


  fetchUserDevices(): void {

    this.apicaller.CallEndPoint<UserOrgRelationObj[], any>('/api/user_devices', {}, CallType.GET, (r) => {

      this.userOrgData = r;
      //move user home org to top
      for (let i = 0; i < this.userOrgData.length; i++) {
        if (this.userOrgData[i].id === this.mySession.user.organization.id) {
          moveItemInArray(this.userOrgData, i, 0);
        }
      }
      console.log(this.userOrgData);

      // this.layout = [...this.layout];
      this.fetchUserDashboard();

    }, (e) => {
      console.log(e.error.detail);
    });

  }


  fetchUserDashboard() {
    if (SESSION.user?.dash) {

      console.log("GOT DASH", SESSION.user.dash);
      this.hasDashItems = true;
      this.DashCharts = [...SESSION.user.dash];

      this.refreshDash();

    } else {
      console.log("WAITING FOR DASH");
    }

  }


  //KTD GRID CALLBACKS
  onResizeStarted(e) {
    let dashObj: UserDashObj = this.DashCharts.find(ite => ite.grid_location.id === e.layoutItem.id);
    let graphComponent: GraphCardComponent = this.graphCards.find(graph => graph.nativeChartObj.topic.id === dashObj.native_chart.topic.id)
    //console.log(graphComponent.nativeChartObj.topic.soft_name," was resized")
    //graphComponent.refreshGraphDelayed(300);
    graphComponent.startResizing();
  }

  onResizeEnded(e) {
    let dashObj: UserDashObj = this.DashCharts.find(ite => ite.grid_location.id === e.layoutItem.id);
    let graphComponent: GraphCardComponent = this.graphCards.find(graph => graph.nativeChartObj.topic.id === dashObj.native_chart.topic.id)
    //console.log(graphComponent.nativeChartObj.topic.soft_name," was resized")
    graphComponent.endResizing();
    graphComponent.refreshGraphDelayed(300);

  }
  

  onLayoutUpdated(e) {
    //console.log(e);

    this.ktdGrid = [...e];

    this.ktdGrid.forEach(gridItem => {

      let dashObj: UserDashObj = this.DashCharts.find(ite => ite.grid_location.id === gridItem.id);
      dashObj.grid_location = JSON.parse(JSON.stringify(gridItem));
    })

    this.saveUserDashboard();

  }
  //==============


  refreshDash() {
    this.hasDashItems = true;
    let newGrid: KtdGridLayout = [];

    this.DashCharts.forEach(item => {

      newGrid.push(item.grid_location)
    })

    this.ktdGrid = [...newGrid];

  }


  onDeviceSelected(device: DeviceObj): void {
    console.log('GOOF DEVICE', device)
    this.selectedDevice = JSON.parse(JSON.stringify(device));

    this.deviceManager?.fetchInputDefinitions(this.selectedDevice.id);
    this.deviceManager?.fetchLinks(this.selectedDevice.id);
    this.deviceManager?.fetchCommands(this.selectedDevice.id);
  }

  onCloseDeviceManager() {
    this.selectedDevice = null;
  }


  onDeviceAddedToDash(input: UserDashObj) {

    this.DashCharts.push(JSON.parse(JSON.stringify(input)));

    this.refreshDash();
    this.saveUserDashboard();

    console.log(input);
  }


  saveUserDashboard(graphToUpdate: GraphCardComponent = null) {

    console.log('ASDFASDFASDF', this.DashCharts);
    if (this.DashCharts.length === 0) {
      this.DashCharts === null;
      this.hasDashItems = false;
    }

    this.apicaller.CallEndPoint<any, any>('/api/save_dashboard', { "items": this.DashCharts }, CallType.POST, (r) => {

      console.log(r);
      if (graphToUpdate) {
        graphToUpdate.fetchSeries();
      }

    }, (e) => {
      console.log(e.error.detail);

    });
  }

  getDashItemByID(id: string): UserDashObj {

    let item = this.DashCharts.find(ite => ite.grid_location.id === id)
    return item;

  }


  onRemoveChartFromDash(DashItem: UserDashObj) {
    console.log("REM DASH ITE", DashItem);
    console.log("FROM ITEMS", this.DashCharts);

    let remIndex = this.DashCharts.findIndex(ite => ite.grid_location.id === DashItem.grid_location.id);
    console.log('REMDEX', remIndex);
    this.DashCharts.splice(remIndex, 1);

    this.refreshDash();
    this.saveUserDashboard();
  }


}
