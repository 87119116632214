import { Component, OnInit, Input, EventEmitter, Output, ValueSansProvider } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';


import { DeviceObj, TopicObj, NativeChartObj, UserDashObj } from '../../../app.censir.api.defs';
import { APICaller, CallType } from 'src/app/app.censir.api_caller';
import { ThemeManager } from '../../app.theme-manager';
import { UnixDateFactory } from '../../app.global-manager';

@Component({
  selector: 'app-device-card',
  templateUrl: './device-card.component.html',
  styleUrls: ['./device-card.component.scss'],
  providers: [DatePipe]
})
export class DeviceCardComponent implements OnInit {

  @Input() Device: DeviceObj;

  @Output() deviceSelected = new EventEmitter<DeviceObj>();
  @Output() closeManager = new EventEmitter();

  thememan: ThemeManager;
  unixDates = new UnixDateFactory();

  //incoming
  selectedTopic: TopicObj;
  deviceCharts: NativeChartObj[];

  last_stat: any;

  last_stat_items: string[] = [];

  constructor(public datePipe: DatePipe, private apicaller: APICaller, private toast: MatSnackBar) {
    this.thememan = ThemeManager.themeManager;
  }


  ngOnInit(): void {

    console.log(this.Device.last_stat);
    try {

      this.last_stat = JSON.parse(this.Device.last_stat);

      for (let key in this.last_stat) {
        if (key != 'last_seen') {

          this.last_stat_items.push(`${key}: ${this.last_stat[key]}`)
        }
      }
    } catch (error) {
      this.last_stat = undefined;
    }

  }

  getLastSeenDate() {

    let date = this.last_stat ? this.datePipe.transform(this.last_stat?.last_seen, 'YYYY-MM-dd hh:mm:ss a') : 'Never';

    return date;
  }

  callDeviceSelected(device: DeviceObj): void {

    this.deviceSelected.emit(device);

  }



}
