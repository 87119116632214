<mat-sidenav-container style="position: absolute; left: 0; right: 0px; top: 60px; bottom: 0px;">

    <mat-card class='mat-elevation-z16' configMode>
        <mat-card-title style="height:38px;">
            <!-- <button mat-mini-fab aria-label="Example icon button with a home icon" style="float:right; z-index: 10;" >
            <mat-icon>close</mat-icon>
        </button> -->
            <div style="height: 10px;"></div>


        </mat-card-title>
        <mat-card-subtitle>


        </mat-card-subtitle>

        <hr>
        <mat-card-content>
            <div>


                <mat-tab-group mat-align-tabs="center" dynamicHeight tab-panel>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon class="example-tab-icon">user</mat-icon>
                            User
                        </ng-template>

                        <div style="display: flex; padding: 10px; padding-bottom: 20px;">

                            <div style="margin-right: 20px; flex-grow:1; max-width: 48%;width:48%;height: 400px; ">

                                <div style="width:100%; font-size: 2em; margin-bottom:0px; padding-bottom: 0px;"><a style="margin-left: 46%;">Users</a></div>


                                <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0 demo-table">

                                    <!-- Name Column -->
                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                                        <td mat-cell *matCellDef="let user">{{user.name}}</td>
                                    </ng-container>

                                    <!-- Surname Column -->
                                    <ng-container matColumnDef="surname">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Surname</th>
                                        <td mat-cell *matCellDef="let user">{{user.surname}}</td>
                                    </ng-container>

                                    <!-- email Column -->
                                    <ng-container matColumnDef="email">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                                        <td mat-cell *matCellDef="let user">{{user.email}}</td>
                                    </ng-container>

                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="home org">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Org</th>
                                        <td mat-cell *matCellDef="let user">{{user.organization.name}}</td>
                                    </ng-container>

                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="in_date">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Since</th>
                                        <td mat-cell *matCellDef="let user">{{user.in_date}}</td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns" [class]="thememan.activeTheme + ' theme-fg-accent'"></tr>
                                    <tr
                                        mat-row
                                        (click)="tableItemClicked(row)"
                                        [class.demo-row-is-clicked]="clickedRows.has(row)"
                                        *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>

                                <mat-paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>


                            </div>

                            <mat-divider [vertical]="true"></mat-divider>

                            <div *ngIf="selectedUser" style="flex-grow:1; max-width: 48%;width:48%;height: 400px; ">
                                <div style="width:100%; font-size: 2em; margin-bottom:0px; padding-bottom: 0px;"><a style="margin-left: 46%;">User</a></div>


                                <div style="display: flex; flex-direction: column; justify-content: space-between; padding-top: 20px; padding-left:15px; padding-right: 15px;">

                                    <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; ">

                                        <mat-form-field style="width: 100%; margin-right: 15px;" appearance="outline">
                                            <mat-label>Name</mat-label>
                                            <input [(ngModel)]="selectedUser.name" autocomplete="off"
                                                   matInput
                                                   type="text" />
                                        </mat-form-field>


                                        <mat-form-field style="width: 100%; " appearance="outline">
                                            <mat-label>Surname</mat-label>
                                            <input [(ngModel)]="selectedUser.surname" autocomplete="off"
                                                   matInput
                                                   type="text" />
                                        </mat-form-field>



                                    </div>

                                    <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; ">


                                        <mat-form-field style="width: 100%; margin-right: 15px;" appearance="outline">
                                            <mat-label>Email</mat-label>
                                            <input [(ngModel)]="selectedUser.email" autocomplete="off"
                                                   matInput
                                                   type="text" />
                                        </mat-form-field>


                                        <mat-form-field style="width: 100%;" appearance="outline">
                                            <mat-label>Phone</mat-label>
                                            <input [(ngModel)]="selectedUser.phone" autocomplete="off"
                                                   matInput
                                                   type="text" />
                                        </mat-form-field>




                                    </div>

                                    <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; ">

                                        <mat-form-field class="dynaform-row-item" appearance="outline" style="flex-grow: 1; margin-right: 15px;">
                                            <mat-label>User Branch Access</mat-label>
                                            <mat-select multiple [(ngModel)]="selectedUser.branches" (selectionChange)="test();">

                                                <mat-select-trigger>
                                                    {{selectedUser ? selectedUser.branches[0]?.name : ''}}
                                                    <span *ngIf="selectedUser.branches?.length > 1" class="example-additional-selection">
                                                        (+{{selectedUser.branches?.length - 1}} {{selectedUser.branches?.length === 2 ? 'other' : 'others'}})
                                                    </span>
                                                </mat-select-trigger>

                                                <mat-option *ngFor="let branch of activeBranches" [value]="branch">
                                                    {{mySession.user.organization.name}}/{{branch.name}}
                                                </mat-option>
                                                <mat-option *ngFor="let branch of inactiveBranches" [value]="branch">
                                                    {{mySession.user.organization.name}}/{{branch.name}}
                                                </mat-option>
                                            </mat-select>

                                        </mat-form-field>


                                        <mat-form-field class="dynaform-row-item" appearance="outline" style="flex-grow: 1; ">
                                            <mat-label>User Type</mat-label>
                                            <mat-select [(ngModel)]="selectedUser.is_admin">
                                                <mat-option [value]=false>Org. User </mat-option>
                                                <mat-option [value]=true>Org. Administrator </mat-option>
                                            </mat-select>

                                        </mat-form-field>

                                    </div>

                                    <div style="margin-top: 50px; display: flex; flex-direction: row; justify-content:flex-end;">

                                        <button mat-raised-button color="accent" style=" width:40%;"
                                                aria-label="Example icon button with a home icon" (click)="saveUser()">
                                            <mat-icon ico-margin>save</mat-icon>Save
                                        </button>


                                    </div>


                                </div>



                            </div>


                        </div>

                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label (click)="fetchInputDefinitions();">
                            <mat-icon class="example-tab-icon">target</mat-icon>
                            wip*
                        </ng-template>



                    </mat-tab>

                    <div style="display: flex; padding: 20px; padding-bottom: 20px;">

                        <mat-card class="mat-elevation-z12" style="margin-right: 50px; flex-grow:1; max-width: 45%;width:45% ">

                            <div style="width:100%; font-size: 2em; margin-bottom:0px; padding-bottom: 0px;"><a style="margin-left: 45%;">Topics</a></div>
                            <hr>


                        </mat-card>

                        <mat-card class="mat-elevation-z12" style="flex-grow:1; width:45%">
                            <div style="width:100%; font-size: 2em; margin-bottom:0px; padding-bottom: 0px;"><a style="margin-left: 45%;">Topic</a></div>
                            <hr>


                        </mat-card>

                    </div>


                </mat-tab-group>

            </div>
        </mat-card-content>
    </mat-card>

</mat-sidenav-container>