<mat-sidenav-container class="sidenav-container">

  <mat-sidenav #drawer class="sidenav" fixedInViewport="false" mode="over" (click)="drawer.toggle()">
    <mat-toolbar>Censir Navigation</mat-toolbar>
    <mat-nav-list>

      <h3>&nbsp;Places <mat-divider></mat-divider>
      </h3>

      <a mat-list-item [routerLink]="['default']">
        <mat-icon color="accent">dashboard</mat-icon>&nbsp; Dashboard
      </a>

      <a mat-list-item [routerLink]="['users']">
        <mat-icon color="accent">group</mat-icon>&nbsp; Users
      </a>

      <a mat-list-item [href]="apicaller.hostUrl + '/grafana/'" target="_blank">
        <mat-icon color="accent">waterfall_chart</mat-icon>&nbsp; Grafana
      </a>

      <!-- <h3>&nbsp;Tools <mat-divider></mat-divider>
      </h3>

      <a mat-list-item [routerLink]="['/dynaform']">
        <mat-icon>dynamic_form</mat-icon>&nbsp; Dynaform
      </a> -->

      <div *ngIf="mySession.user?.is_superuser">

        <h3>&nbsp;Super User <mat-divider></mat-divider>
        </h3>

        <a mat-list-item [href]="apicaller.influxURL" target="_blank">
          <mat-icon color="accent">timeline</mat-icon>&nbsp; Influx
        </a>

        <a mat-list-item [href]="apicaller.hostUrl + '/sql/'" target="_blank">
          <mat-icon color="accent">table_chart</mat-icon>&nbsp; Adminer
        </a>


        <a mat-list-item [href]="apicaller.hostUrl + '/admin/'" target="_blank">
          <mat-icon color="accent">admin_panel_settings</mat-icon>&nbsp; Django Admin
        </a>

        <a *ngIf="apicaller.isProduction" mat-list-item [href]="apicaller.devUrl" target="_blank">
          <mat-icon color="accent">developer_mode</mat-icon>&nbsp; Censir UAT
        </a>
        
        <a *ngIf="!apicaller.isProduction" mat-list-item [href]="apicaller.prodUrl" target="_blank">
          <mat-icon color="accent">precision_manufacturing</mat-icon>&nbsp; Censir Production
        </a>

      </div>



      <h3>&nbsp;Actions <mat-divider></mat-divider>
      </h3>

      <a mat-list-item [routerLink]="['']" (click)="deauthUser();">
        <mat-icon>logout</mat-icon>&nbsp; Logout
      </a>


    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>


    <div make-sticky *ngIf="mySession.isAuthed">

      <mat-toolbar color="primary" class="mat-elevation-z8" style="z-index: 999;">

        <mat-toolbar-row>
          <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
            <mat-icon aria-label="Side nav toggle icon" color="accent">menu</mat-icon>
          </button>
          <div style="width: 100%;">
            <!-- <div devframe style="width:100px;height: 80px;"><a [routerLink]="['']"><img src="../../../../assets/f6c06f2e37834740b0c6f16b3a2d375a (2).png" alt=""></a></div> -->
            <span style="font-size: 2em; font-weight: 500;">{{mySession.title}}</span>
            <span style="font-size: 1em; font-weight: 300;">{{mySession.version}}</span>

          </div>
          <span class="toolbar-spacer"></span>

          <mat-divider [vertical]="true"></mat-divider>

          <mat-divider [vertical]="true"></mat-divider>
          <span style="font-size: 1em; font-weight: 300; float:right;">{{mySession.userFullName}}</span> &nbsp;

          <button mat-mini-fab id="theme-selector" [mat-menu-trigger-for]="themeMenu" matTooltip="User Menu"
                  tabindex="-1" style="font-size: 1.1em;">{{mySession.userInitials}}
            <!-- <mat-icon>person</mat-icon> -->
          </button>

          <mat-menu #themeMenu="matMenu" style="justify-content: center;">
            <mat-slide-toggle [checked]="themeManager.isThemeDark" (click)="$event.stopPropagation()"
                              (change)="themeManager.SetDarkMode = $event.checked;" class="mat-menu-item menu-font">Dark
              Mode
            </mat-slide-toggle>

            <mat-grid-list cols="2" style="width: 100%;">

              <mat-grid-tile *ngFor="let theme of themeManager.themes">
                <button mat-button (click)="$event.stopPropagation(); themeManager.SetTheme = theme;">
                  <div [attr.class]="theme + ' theme-primary center'">
                    <div [attr.class]="theme + ' theme-accent'"></div>
                    <mat-icon *ngIf="themeManager.activeTheme === theme" color="warn" class="center theme-check">check</mat-icon>
                  </div>
                </button>

              </mat-grid-tile>
            </mat-grid-list>

            <mat-progress-bar mode="determinate" value="100"></mat-progress-bar>

            <a class="mat-menu-item menu-font" mat-list-item [routerLink]="['']" (click)="deauthUser();">
              <mat-icon>password</mat-icon>&nbsp; Change Password
            </a>

            <a class="mat-menu-item menu-font" mat-list-item [routerLink]="['']" (click)="deauthUser(); drawer.close()">
              <mat-icon class="theme-fg-primary">logout</mat-icon>&nbsp; Sign Out
            </a>


            <!-- <h3 [class]="themeManager.activeTheme + ' theme-bg-primary mat-menu-item ' ">Theme Test</h3> -->

          </mat-menu>

        </mat-toolbar-row>



      </mat-toolbar>


      <!-- <app-agent-page *ngIf="!isAdmin" (AgentDialerPage)="AgentDialerRef"></app-agent-page> -->


    </div>




    <router-outlet> </router-outlet>



  </mat-sidenav-content>


</mat-sidenav-container>