import { Component, HostBinding, OnInit } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { ThemeManager } from '../app/components/app.theme-manager';
import { APICaller, CallType } from './app.censir.api_caller';
import { LocalStorageService } from './components/app.local-storage-manager';
import { SESSION } from '../app/components/app.global-manager';
import { UserObj } from './app.censir.api.defs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppRootComponent implements OnInit {
  static appRootComponent: AppRootComponent //stupid singleton!

  themeManager: ThemeManager;


  mySession = SESSION;

  @HostBinding('class') activeThemeCssClass: string;


  constructor(private router: Router, public apicaller: APICaller, private toast: MatSnackBar, private overlayContainer: OverlayContainer, private localStorageService: LocalStorageService) {
    AppRootComponent.appRootComponent = this;

    this.themeManager = new ThemeManager(overlayContainer, this.activeThemeCssClass, localStorageService, cssClass => {
      console.log(this.activeThemeCssClass + ' changed to ' + cssClass);
      this.activeThemeCssClass = cssClass;
    });

    ThemeManager.themeManager = this.themeManager;



  }


  ngOnInit(): void {
    this.authedUser();
  }




  authUser(username: string, password: string): void {
    const body = { email: username, password: password };

    this.apicaller.CallEndPoint<UserObj, any>('/api/auth_user', body, CallType.POST, (r) => {

      SESSION.setUser(r);

      this.router.navigate(['default']);
    }, (e) => {
      this.toast.open('invalid credentials', 'got it!', { duration: 3000 });
    });


    //log in to grafana
    this.apicaller.CallEndPoint<any, any>('/grafana/login', { user: username, password: password }, CallType.POST, (r) => {

      console.log(r);

    }, (e) => {
      this.toast.open('invalid grafana credentials', 'got it!', { duration: 3000 });
    });

  }


  authedUser(): void {

    this.apicaller.CallEndPoint<UserObj, any>('/api/authed_user', {}, CallType.GET, (r) => {
     
      SESSION.setUser(r);
      console.log(SESSION.user);
      this.router.navigate(['default']);

    }, (e) => {
      this.toast.open('no session exists, please sign in.', 'got it!', { duration: 2000 });
    });

  }


  deauthUser(): void {

    this.apicaller.CallEndPoint<any, any>('/api/deauth_user', {}, CallType.POST, (r) => {
      SESSION.user = null;
      SESSION.isAuthed = false;
      this.toast.open('your session has been safely closed', 'got it!', { duration: 3000 });
    });

  }








}



