import { Component, OnInit, Input, ViewChild, EventEmitter, Output , AfterViewInit} from '@angular/core';

import { ThemeManager } from '../../app.theme-manager';
import { colorSets, LineChartComponent } from '@swimlane/ngx-charts';

import { DeviceObj, UserDashObj, NativeChartObj, InputObj, LineChartObj, Series } from '../../../app.censir.api.defs';
import { APICaller, CallType } from '../../../app.censir.api_caller';
import { DatePipe } from '@angular/common';
import { DateRange } from '@angular/material/datepicker';
import { UnixDateFactory } from '../../app.global-manager';

import { SafePipe } from '../../app.global-manager';

@Component({
  selector: 'app-graph-card',
  templateUrl: './graph-card.component.html',
  styleUrls: ['./graph-card.component.scss'],
  providers: [DatePipe,SafePipe]
})
export class GraphCardComponent implements OnInit {

  thememan: ThemeManager;
  @Input() userDashObj: UserDashObj;
  @Input() maximized: boolean = false;
  @Input() onDash: boolean = false;
  @Input() showTimeline: boolean = false;
  @Input() dashGraphID: string = null;

  @Output() addToDash = new EventEmitter<UserDashObj>();
  @Output() removeFromDash = new EventEmitter<UserDashObj>();


  @ViewChild('chart') chart: LineChartComponent;


  nativeChartObj: NativeChartObj;


  unixDates = new UnixDateFactory();
  // curInputs: InputObj[];

  curLineObj: LineChartObj;


  private interval: any;
  public chartData: { name: string, series: { name: Date | string, value: number }[] }[];

  colorScheme = {
    domain: ['#888888', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  xAxisLabel: string = 'Time';
  yAxisLabel: string = 'VInput';
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  showGridLines: boolean = false;
  defualtTime: any;
  autoScale: boolean = true;

  influxStart: string = "-1h";
  influxWindow: String = "1m";

  //
  isResizing: boolean = false;

  constructor(private apicaller: APICaller, private datePipe: DatePipe, public safe: SafePipe) {
    this.thememan = ThemeManager.themeManager;

  }

  pop() {
    console.log("YEHAA FROM: ", this.userDashObj.native_chart.topic.soft_name)
  }


  async startResizing() {
    this.isResizing = true;

    while (this.isResizing) {
      await new Promise(r => setTimeout(r, 50));
      this.chart.update();
    }
  }


  endResizing() {
    this.isResizing = false;
  }

  // packageDashComponent(nativeChart: NativeChartObj): UserDashObj {
  //   let newDashItem: UserDashObj = {
  //     "is_native": true,
  //     "external_chart_url": null,
  //     "grid_location": {
  //       "id": this.dashGraphID ?? this.unixDates.getUUIDv4(),
  //       "x": 0,
  //       "y": 0,
  //       "w": 6,
  //       "h": 3,
  //     },
  //     "native_chart": nativeChart
  //   }
  //   return newDashItem
  // }

  callAddToDash(): void {
    this.addToDash.emit(this.userDashObj);
  }


  callRemoveFromDash(): void {
    this.removeFromDash.emit(this.userDashObj);
  }


  ngOnInit(): void {


    this.nativeChartObj = this.userDashObj.native_chart;
    if (this.userDashObj.is_native) {

      this.fetchSeries();
      this.interval = setInterval(() => {
        this.fetchSeries();

      }, 5000);

    }
  }

  ngAfterViewInit() {
   

    let iframe: HTMLIFrameElement = <HTMLIFrameElement>document.getElementById(this.userDashObj.grid_location.id);
   // iframe.src = this.$iframe.widget.iframe_widget.url;
   iframe.setAttribute("src",this.userDashObj.external_chart_url.toString()); 
  }



  makeDate(val): string {
    return this.datePipe.transform(val, "YYYY-MM-dd hh:mm:ss a")
  }


  formatPercent(val: Date) {
    //  let uxd = new UnixDateFactory();

    // if(typeof val === 'string' ){
    //   console.log("STRINGDATETRAP",val);
    //   val = uxd.unixToDate(Date.parse(val));
    //   console.log(val);

    // }
    // if (val) {

    const datePipe: DatePipe = new DatePipe("en_US")

    //   if (val.getSeconds() != 0) {
    return datePipe.transform(val, 'hh:mm:ss a')

    //   } else {

    //     return datePipe.transform(val, 'hh:mm a')
    //   }
    // }
  }

  getcolor() {
    try {
      let div = document.getElementById('graph-card');
      const style = getComputedStyle(div)
      this.colorScheme.domain[0] = style.color;
      //console.log("GOOF COLOR", this.colorScheme.domain[0], div, style);
    } catch (error) {
      this.colorScheme.domain[0] = "#FFFFFF";
    }


  }

  ngOnDestroy(): void {
    clearInterval(this.interval);

  }

  async fetchSeriesDelayed(delay_ms: number) {
    await new Promise(r => setTimeout(r, delay_ms));
    this.fetchSeries();
  }

  fetchSeries(): void {

    if (this.nativeChartObj.topic.ingest_destination == 1) {

      this.apicaller.CallEndPoint<any, any>('/api/get_series/' + this.nativeChartObj.topic.id, {}, CallType.GET, (r) => {

        this.getcolor();

        if (!this.chartData) {
          this.chartData = [{ name: this.nativeChartObj.topic.hard_name, series: [] }];

        }

        if (r.data[0]) {
          this.buildLineChart(r.data);
        }

      }, (e) => {
        console.log(e.error.detail);
      });

    }


    if (this.nativeChartObj.topic.ingest_destination == 2) {

      this.apicaller.CallEndPoint<any, any>(`/api/test_flux/${this.nativeChartObj.topic.device}/${this.nativeChartObj.topic.hard_name}/${this.influxStart}/0m/${this.influxWindow}`, {}, CallType.GET, (r) => {

        this.getcolor();
        r.data.forEach(point => {
          point.name = this.unixDates.unixToDate(point.name);
        });

        if (!this.chartData) {
          this.chartData = [{ name: this.nativeChartObj.topic.hard_name, series: [] }];

        }

        let newIte: LineChartObj = {
          'name': this.nativeChartObj.topic.hard_name,
          'series': []
        }


        this.curLineObj = newIte;

        this.curLineObj.series = [...r.data];
        //console.log(newIte);

        // console.log(this.curLineObj)

        this.refreshGraph();

      }, (e) => {
        console.log(e.error.detail);
      });

    }

  }


  buildLineChart(curInputs: InputObj[]): void {

    let series: Series[] = new Array();

    let newIte: LineChartObj = {
      'name': curInputs[0].topic,
      'series': []
    }


    this.curLineObj = newIte;


    let ns: Series[] = new Array();


    for (let ite = 0; ite < curInputs.length; ite++) {
      let nss = {
        'name': new Date(curInputs[ite].in_date),
        'value': Number(curInputs[ite].value)
      }

      ns.push(nss);

    }


    this.curLineObj.series = [...ns];
    //console.log(newIte);

    this.refreshGraph();
  }

  curval: number = 0;

  async refreshGraphDelayed(delay_ms: number) {
    await new Promise(r => setTimeout(r, delay_ms));
    this.refreshGraph();
  }

  refreshGraph() {


    this.chartData[0].series = [...this.curLineObj.series]

    this.chartData = [...this.chartData];

    // this.yAxisLabel = this.inputDefinition.input_si_unit;

    this.curval = this.curLineObj?.series[0]?.value;


    if (this.chart.filteredDomain) {



      let periodZoom = new Date(this.chart.filteredDomain[0]).getMilliseconds();
      let periodMain = new Date(this.chart.timelineXDomain[0]).getMilliseconds();

      if (periodZoom === periodMain) {

        this.chart.filteredDomain = null;
        this.chart.update;
        // console.log("MATCHED!!!");
      }
    }


    //this.showTimeline = !this.showTimeline;


  }


  onSelect(event) {
    // console.log(event);
  }


  scaleChanged() {
    // this.chartData = [...this.chartData];
    // this.chart.update();
    console.log('min:' + this.chart.yScaleMin, this.chart.yScaleMax);

  }

  formatLabel(value: number) {

    return value + 'f';

  }











}
