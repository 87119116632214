import { OverlayContainer } from '@angular/cdk/overlay';
import { LocalStorageService, SavedThemeObj } from './app.local-storage-manager';
import { Injectable } from '@angular/core';

const THEME_DARKNESS_SUFFIX = `-dark`;

@Injectable({
  providedIn: 'root',
})
export class ThemeManager {


  static themeManager: ThemeManager;

  currentTheme: string;

  public themes: string[] = [

    'pink-bluegrey',
    'purple-green',
    'purple-green1',
    'purple-green8',
    'purple-green9',
    'purple-green10'
  ];

  isThemeDark = false;
  activeTheme: string;
  classBinding: string;
  savedThemeObj: SavedThemeObj;

  // tslint:disable-next-line: max-line-length
  constructor(private overlayContainer: OverlayContainer, public parentClassBinding: string, private localStorageService: LocalStorageService, private changeCallback: (cssClass: string) => any) {

    LocalStorageService.StorageManager = localStorageService;

    this.savedThemeObj = LocalStorageService.StorageManager.loadThemeFromClient();
    console.log("SAVED THEME IS:",this.savedThemeObj);
    
    if (!this.savedThemeObj) {
      this.savedThemeObj.isDark = true;
      this.savedThemeObj.name = this.SetTheme = 'purple-green8';
      this.isThemeDark = true;
      this.SetTheme = 'purple-green8';
      this.RefreshTheme();
    }
    else {
      this.isThemeDark = this.savedThemeObj.isDark;
      this.SetTheme = this.savedThemeObj.name;
    }

  }


  public set SetDarkMode(isDark: boolean) {
    this.isThemeDark = isDark;
    this.RefreshTheme();
  }


  public set SetTheme(theme: string) {
    this.activeTheme = theme;
    this.RefreshTheme();
  }


  private RefreshTheme(): void {

    const cssClass = this.isThemeDark === true ? this.activeTheme + THEME_DARKNESS_SUFFIX : this.activeTheme;
    const classList = this.overlayContainer.getContainerElement().classList;

    if (classList.contains(this.parentClassBinding)) {
      classList.replace(this.parentClassBinding, cssClass);
    }
    else {
      classList.add(cssClass);
    }

    this.parentClassBinding = cssClass;

    this.changeCallback(cssClass);

    this.savedThemeObj.isDark = this.isThemeDark;
    this.savedThemeObj.name = this.activeTheme;
    LocalStorageService.StorageManager.saveCurrentThemeOnClient(this.savedThemeObj);
    
  }



}
