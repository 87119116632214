import { AfterViewInit, Component, ViewChild } from '@angular/core';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { transferArrayItem } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';

import { UserManagementObj, TopicObj, NativeChartObj, UserDashObj, Branch } from '../../../app.censir.api.defs';
import { APICaller, CallType } from 'src/app/app.censir.api_caller';
import { ThemeManager } from '../../app.theme-manager';
import { SESSION, JsonObject } from '../../app.global-manager';


export interface PeriodicElement {
    name: string;
    position: number;
    weight: number;
    symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
    { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
    { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
    { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
    { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
    { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
    { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
    { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
    { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
    { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
    { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
];


@Component({
    selector: 'app-user-managment',
    templateUrl: 'user-managment.component.html',
    styleUrls: ['user-managment.component.scss']
})
export class UserManagmentComponent implements AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    thememan: ThemeManager;
    mySession = SESSION;

    clickedRows = new Set<UserManagementObj>();
    displayedColumns: string[] = ['name', 'surname', 'email', 'home org', 'in_date'];
    dataSource;


    selectedUser: UserManagementObj;
    allOrgBranches: Branch[];            //all the branches authed users ORG has
    activeBranches: Branch[];            //branches the user currently has access to
    inactiveBranches: Branch[];          // all branches of ORG minus the ones already active on the user
    isolatedBranches: Branch[] = [];     //branches active on user but from another org. 

    constructor(private apicaller: APICaller, private toast: MatSnackBar) {
        this.thememan = ThemeManager.themeManager;
    }


    ngAfterViewInit() {


        this.getOrgUsers();
        this.getOrgBranches();
    }

    tableItemClicked(user: UserManagementObj) {


        this.selectedUser = JsonObject.Copy(user);

        this.isolateBranchesOfOtherOrg(this.selectedUser);

        this.activeBranches = this.selectedUser.branches;
        this.inactiveBranches = this.filterActiveBranches(this.allOrgBranches, this.selectedUser.branches)


        this.clickedRows.clear();
        this.clickedRows.add(user);

    }

    getOrgBranches() {


        this.apicaller.CallEndPoint<Branch[], any>('/api/all_org_branches', {}, CallType.GET, (r) => {
            this.allOrgBranches = r;


        }, (e) => {
            console.log(e.error.detail);
        });
    }

    getOrgUsers() {
        this.apicaller.CallEndPoint<UserManagementObj[], any>('/api/all_org_users', {}, CallType.GET, (r) => {


            this.dataSource = new MatTableDataSource<UserManagementObj>(r);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;

            this.tableItemClicked(r[0]);


        }, (e) => {
            console.log(e.error.detail);
        });
    }

    test() {

        // console.table(this.selectedUser.branches);

    }


    filterActiveBranches(allOrgBranches: Branch[], activeBranches: Branch[]): Branch[] {

        let found: boolean = false;
        let inactiveBranches: Branch[] = [];

        allOrgBranches.forEach(branch => {
            found = false;
            activeBranches.forEach(activeBranch => {
                if (activeBranch.id === branch.id) {
                    found = true;
                }
            })
            if (!found) {
                inactiveBranches.push(branch);
            }
        })

        return inactiveBranches;

    }


    isolateBranchesOfOtherOrg(selectedUser: UserManagementObj) {
        const curOrgID = this.mySession.user.organization.id;
        this.isolatedBranches = [];
        let exposedBranches = [];
        selectedUser.branches.forEach(branch => {

            if (branch.organization != curOrgID) {

                this.isolatedBranches.push(branch);
            } else {
                exposedBranches.push(branch);
            }

        })
        selectedUser.branches = exposedBranches;


    }

    saveUser() {

        this.selectedUser.branches = this.isolatedBranches.concat(this.selectedUser.branches);

         //copy
        let user = JsonObject.Copy(this.selectedUser);

         //prep object for saving
        delete user.organization;

             //flatten branches
         let branchIDs:number[] = [];
         this.selectedUser.branches.forEach(branch=>{
             branchIDs.push(branch.id);
         })

         user.branches = branchIDs;


        console.log(user);



        this.apicaller.CallEndPoint<UserManagementObj[], any>('/api/save_user', user, CallType.PUT, (r) => {


            this.toast.open('saved', 'got it!', { duration: 3000 });


            this.getOrgUsers();
            this.getOrgBranches();
        }, (e) => {
            console.log(e.error.detail);
        });
    }


}
