import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgMaterialModule } from './ngmaterial.module';

import { StorageServiceModule, LOCAL_STORAGE } from 'ngx-webstorage-service';

import { AppRootComponent } from './app.component';


// Pages
import { LoginComponent } from './components/pages/login/login.component';
import { UserManagmentComponent } from './components/pages/user-managment/user-managment.component';


// Augments
import { SafePipe, FilterPipe } from './components/app.global-manager';
import { APICaller } from './app.censir.api_caller';

// Componenents
import { DefaultComponent } from './components/pages/default/default.component';
import { DeviceCardComponent } from './components/widgets/device-card/device-card.component';
import { DeviceManagerComponent } from './components/widgets/device-manager/device-manager.component';
import { UserCardComponent } from './components/widgets/user-card/user-card.component';
//Directives

//3rd party addons
import { LineChartModule, NgxChartsModule } from '@swimlane/ngx-charts';
import { GraphCardComponent } from '../app/components/widgets/graph-card/graph-card.component';
import { KtdGridModule } from '@katoid/angular-grid-layout';


// git init
// git add . <-- add all
// git reset --hard $COMMIT CODE FROM SITE$
// git push $push locally staged changes to git$
// git clone $repository url$
// npm install <-- to fix a new clone
// git pull <-- latest


@NgModule({
  declarations: [
    AppRootComponent,
    LoginComponent,
    DefaultComponent,


    UserCardComponent,
    DeviceCardComponent,
    DeviceManagerComponent,
    UserManagmentComponent,
    //charts


    SafePipe,
    FilterPipe,
    GraphCardComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgMaterialModule,
    StorageServiceModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,

    NgxChartsModule,
    LineChartModule,
    KtdGridModule,
    
    // KEEP LAST:
    AppRoutingModule
  ],
  providers: [APICaller],
  bootstrap: [AppRootComponent]
})
export class AppModule { }
