import { Component, OnInit, Input, EventEmitter, Output, ValueSansProvider } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';


import { DeviceObj, TopicObj, NativeChartObj, UserDashObj } from '../../../app.censir.api.defs';
import { APICaller, CallType } from 'src/app/app.censir.api_caller';
import { ThemeManager } from '../../app.theme-manager';
import { UnixDateFactory } from '../../app.global-manager';
import { SESSION } from '../../app.global-manager';
// Generated by https://quicktype.io

// Generated by https://quicktype.io

export interface HopperCmdObj {

    topic: string;
    to_device: string;
    ack: string;
    command: string;
    value: string;
    sent: boolean;
    commander: string;
    ack_date?: string;
    in_date?: string;
}



export interface DeviceCmdObj {
    id: number;
    topic: string;
    command: string;
    value: string;
    in_date: string;
    ack?: string;
    ack_date: string;
    cmd_date?: string;
    device: Device;
}


export interface IFrameObj {
    id: number;
    note: string;
    name: string;
    url: string;
    in_date: string;
    device: Device;
    topic: Topic;
}

export interface Device {
    id: number;
    hard_name: string;
    soft_name: string;
    address: null;
    lat: number;
    lon: number;
    last_stat: string;
    ip_address: string;
    ingest_tokens: null;
    is_active: boolean;
    in_date: string;
    organization: number;
    branch: number;
}

export interface Topic {
    id: number;
    hard_name: string;
    soft_name: string;
    si_unit: null;
    bool_true: string;
    bool_false: string;
    ingest_destination: number;
    ingest_active: boolean;
    in_date: string;
    device: number;
}



@Component({
    selector: 'app-device-manager',
    templateUrl: 'device-manager.component.html',
    styleUrls: ['device-manager.component.scss'],
    providers: [DatePipe]
})
export class DeviceManagerComponent implements OnInit {

    @Input() Device: DeviceObj;

    @Output() addToDash = new EventEmitter<UserDashObj>();
    @Output() closeManager = new EventEmitter();
    @Output() refreshDevices = new EventEmitter();

    thememan: ThemeManager;
    unixDates = new UnixDateFactory();

    //incoming
    selectedTopic: TopicObj;
    deviceCharts: NativeChartObj[];

    deviceiFrames = new Array<IFrameObj>();
    deviceCmds = new Array<DeviceCmdObj>();
    mysesh = SESSION

    constructor(private apicaller: APICaller, private toast: MatSnackBar, public datePipe: DatePipe) {
        this.thememan = ThemeManager.themeManager;
    }


    ngOnInit(): void {
        this.fetchInputDefinitions(this.Device.id);
        this.fetchLinks(this.Device.id);
        this.fetchCommands(this.Device.id);
    }


    callAddToDash(input: UserDashObj): void {
        this.addToDash.emit(input);
    }


    onTopicSelected(topic: TopicObj) {
        this.selectedTopic = topic;
    }


    onTopicSave() {
        console.log(this.selectedTopic);
        this.apicaller.CallEndPoint<any, any>('/api/save_topic', this.selectedTopic, CallType.POST, (r) => {

            this.selectedTopic = null
            this.refreshDevices.emit();
            this.fetchInputDefinitions(this.Device.id);
        });
    }


    fetchInputDefinitions(device_id: number): void {
        this.deviceCharts = new Array();

        this.apicaller.CallEndPoint<DeviceObj, any>('/api/device_with_topics/' + device_id, {}, CallType.GET, (r) => {

            console.log(r);
            r.topics.forEach(topic => {
                if (topic.ingest_active) {

                    let incomingNativeChart: NativeChartObj = {
                        "topic": topic,
                        "unix_from": this.unixDates.getOffset(-3),
                        "unix_to": this.unixDates.getNow()
                    }

                    this.deviceCharts.push(incomingNativeChart);
                }
            })

        }, (e) => {
            console.log(e.error.detail);
        });
    }

    
    closeMe() {
        this.closeManager.emit();
    }


    fetchLinks(device_id: number) {

        this.apicaller.CallEndPoint<IFrameObj[], any>('/api/device_iframes/' + device_id, {}, CallType.GET, (r) => {

            this.deviceiFrames = r;

        }, (e) => {
            console.log(e.error.detail);
        });      
    }


    updateLink(iframe: IFrameObj) {
        let newiFrame = {
            "id": iframe.id,
            "name": iframe.name,
            "note": iframe.note,
            "url": iframe.url,
            "device": iframe.device.id
        }

        this.apicaller.CallEndPoint<IFrameObj[], any>('/api/update_iframe', newiFrame, CallType.PUT, (r) => {

            this.toast.open('Saved!', 'success');

        }, (e) => {
            console.log(e.error.detail);
        });
    }


    deleteLink(iframe: IFrameObj) {

        this.apicaller.CallEndPoint<IFrameObj[], any>('/api/delete_iframe/' + iframe.id, {}, CallType.DELETE, (r) => {

            this.toast.open('Deleted!', 'success');
            this.fetchInputDefinitions(this.Device.id);
            this.fetchLinks(this.Device.id);
        }, (e) => {
            console.log(e.error.detail);
        });
    }


    addNewLink() {
        let newiFrame = {
            "name": this.Device.soft_name,
            "note": "New Link",
            "url": "",
            "device": this.Device.id
        }

        this.apicaller.CallEndPoint<IFrameObj[], any>('/api/create_iframe', newiFrame, CallType.POST, (r) => {

            this.toast.open('Created!', 'success');
            this.fetchInputDefinitions(this.Device.id);
            this.fetchLinks(this.Device.id);
        }, (e) => {
            console.log(e.error.detail);
        });
    }


    callAddIframeToDash(iframe: IFrameObj): void {
        try {
            let newDash: UserDashObj = {
                "is_native": false,
                "grid_location": { "h": 2, "id": this.unixDates.getUUIDv4(), "w": 6, "x": 0, "y": 0 },
                "external_chart_url": new URL(iframe.url),
                "external_chart_name": iframe.name,
                "external_chart_note": iframe.note,
                "native_chart": { "topic": iframe.topic, "unix_from": 0, "unix_to": 0 }
            }

            this.addToDash.emit(newDash);
        } catch (error) {
            this.toast.open('Make sure your url is valid and has https:// prefix!', 'error');
        } 
    }


    ///device commands
    fetchCommands(device_id: number) {

        this.apicaller.CallEndPoint<DeviceCmdObj[], any>('/api/device_commands/' + device_id, {}, CallType.GET, (r) => {

            this.deviceCmds = r;

            setInterval(() => {
                this.get_cmd_acks();
            }, 2000)

        }, (e) => {
            console.log(e.error.detail);
        });
    }


    updateCommands(DeviceCmdObj: DeviceCmdObj) {

        let newDeviceCmdObj = {
            "id": DeviceCmdObj.id,
            "device": DeviceCmdObj.device.id,
            "topic": DeviceCmdObj.topic,
            "command": DeviceCmdObj.command,
            "value": DeviceCmdObj.value
        }

        this.apicaller.CallEndPoint<DeviceCmdObj[], any>('/api/update_device_cmd', newDeviceCmdObj, CallType.PUT, (r) => {

            this.toast.open('Saved!', 'success');

        }, (e) => {
            console.log(e.error.detail);
        });
    }


    deleteCommands(DeviceCmdObj: DeviceCmdObj) {

        this.apicaller.CallEndPoint<DeviceCmdObj[], any>('/api/delete_device_cmd/' + DeviceCmdObj.id, {}, CallType.DELETE, (r) => {

            this.toast.open('Deleted!', 'success');
            this.fetchCommands(this.Device.id);
        }, (e) => {
            console.log(e.error.detail);
        });
    }


    addNewCommands() {

        let newDeviceCmdObj = {
            "device": this.Device.id,
            "topic": "cmd",
            "command": "new_command",
            "value": 0
        }

        this.apicaller.CallEndPoint<DeviceCmdObj[], any>('/api/create_device_cmd', newDeviceCmdObj, CallType.POST, (r) => {

            this.toast.open('Created!', 'success');
            this.fetchCommands(this.Device.id);
        }, (e) => {
            console.log(e.error.detail);
        });
    }


    sendCommandToHopper(cmd: DeviceCmdObj) {
        this.updateCommands(cmd);
        let newHopperCmd: HopperCmdObj = {
            "topic": `device/${this.Device.hard_name}/sys/${cmd.topic}/${cmd.command}/val`,
            "to_device": this.Device.hard_name,
            "ack": "",
            "command": cmd.command,
            "value": cmd.value,
            "sent": false,
            "commander": this.mysesh.user.email,
        }

        this.apicaller.CallEndPoint<DeviceCmdObj[], any>('/api/add_hopper_cmd', newHopperCmd, CallType.POST, (r) => {

            this.toast.open('Command Sent To Hopper!', 'success');
        }, (e) => {
            console.log(e.error.detail);
        });

    }


    get_cmd_acks() {
        //this.datePipe.transform(this.last_stat?.last_seen, 'YYYY-MM-dd hh:mm:ss a')

        this.deviceCmds.forEach(cmd => {
            let topic = {
                "topic": `device/${this.Device.hard_name}/sys/${cmd.topic}/${cmd.command}/val`
            }

            this.apicaller.CallEndPoint<HopperCmdObj, any>('/api/get_latest_hopper_command', topic, CallType.POST, (r) => {

                console.log(r);
                if (r.ack_date) {
                    cmd['ack_date'] = this.datePipe.transform(r.ack_date, 'YYYY-MM-dd hh:mm:ss a');
                    cmd['ack'] = r.ack;
                    cmd['cmd_date'] = r.in_date;
                    console.log(cmd);
                } else {

                }

            }, (e) => {
                console.log(e.error.detail);
            });
        })

    }


}
