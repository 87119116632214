<div *ngIf="Device">

    <!-- {{datePipe.transform(Device.in_date,'YYYY-MM-dd hh:mm:ss a')}} -->

    <div style="display: flex;flex-direction: row;">
        <div style="width: 70px;">
            <mat-icon color="accent" style="font-size: 5em;">nfc</mat-icon>
        </div>
        <div style="display: flex; flex-direction:column; width: 75%;">
            <a [class]="thememan.activeTheme + ' theme-fg-accent'" style="font-size: 1.4em; font-weight: 600;"><u>{{Device.soft_name}}</u></a>
            <a style="margin-top: 5px;"><b [class]="thememan.activeTheme + ' theme-fg-accent'">Last Seen:</b>{{getLastSeenDate()}}</a>
            <a [class]="thememan.activeTheme + ' theme-fg-accent'" style="width: 100%;" *ngFor="let ite of last_stat_items">{{ite}}</a>
        </div>

    </div>
    <hr>
    <div style="height: 40px; display: flex; flex-direction: row; justify-content: space-evenly;">

        <button mat-raised-button color="primary" style="width:48%;" (click)="callDeviceSelected(Device);">
            <mat-icon color="accent">engineering</mat-icon>
            Configure
        </button>
        <button mat-raised-button color="primary" style="width:48%;" disabled>
            <mat-icon color="accent">visibility</mat-icon>
            Quick View
        </button>
    </div>

</div>

