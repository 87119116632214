<!-- <mat-card *ngIf="userOffice" style="margin:10px;" [class]="themezs">
  <a [class]="thememan.activeTheme + ' theme-fg-accent' " style="font-size: 2em;font-weight: 500;">{{userOffice.name}}</a>
  <mat-progress-bar mode="determinate" value="100"></mat-progress-bar>
  <br>
  <a><b [class]="thememan.activeTheme + ' theme-fg-accent'">Mail:</b> {{userOffice.email}}</a>
  <br>
  <a><b [class]="thememan.activeTheme + ' theme-fg-accent'">Phone:</b> {{userOffice.phone}}</a>
  <br>
  <a><b [class]="thememan.activeTheme + ' theme-fg-accent'">Acc #:</b> {{userOffice.acc_number}}</a>
  <br>
  <a><b [class]="thememan.activeTheme + ' theme-fg-accent'">Creation:</b> {{userOffice.in_date}}</a>


</mat-card> -->

