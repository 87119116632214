<div nozoom>

    <div class="waveWrapper waveAnimation">

        <img src="../../../../assets/f6c06f2e37834740b0c6f16b3a2d375a (2).png" alt="" style="position: absolute; left:43%; top:25px" height="250px">

        <div backgroundRight>
        </div>

        <div backgroundLeft>
        </div>

        <div class="waveWrapperInner bgTop">
            <div class="wave waveTop" style="background-image: url('../../../../assets/wave-top.png')">
            </div>
        </div>
        <div class="waveWrapperInner bgMiddle">
            <div class="wave waveMiddle" style="background-image: url('../../../../assets/wave-mid.png')">
            </div>
        </div>
        <div class="waveWrapperInner bgBottom">
            <div class="wave waveBottom" style="background-image: url('../../../../assets/wave-bot.png')">
            </div>
        </div>

    </div>



    <!-- <img src="../../../../assets/memory-storage-not-css.svg" height="80%"  width="50%" style="position:absolute; top:100px; left:50px;"> -->

    <!-- <a mat-list-item [routerLink]="['/devstaging']" style="color:rgb(33, 138, 230);"> Dev Staging!</a> -->

    <mat-card style="z-index: 900;" mat-elevation-z24>


        <mat-card-title style="font-weight: 200; font-size: 2.5em;">user portal!</mat-card-title>
        &nbsp;
        <mat-card-subtitle *ngIf="!isAuthed" style="font-weight: 100; font-size: 1.5em;">sign in</mat-card-subtitle>

        <mat-progress-bar color="accent" mode="determinate" value="100"></mat-progress-bar>

        <br>

        <mat-card-content>

            <div *ngIf="!isAuthed" class="login-form">

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput #mail type="mail" [(ngModel)]="username">
                    <button mat-icon-button matSuffix (click)="mail.value = ''">
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-form-field>

                <br>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput #pass [type]="hidePass ? 'password' : 'text'" [(ngModel)]="password" (cdkAutofill)="autofilled($event);" (input)="passChanged();">

                    <button *ngIf="!canShowPass" mat-icon-button matSuffix (click)="pass.value = '';canShowPass = true;">
                        <mat-icon>clear</mat-icon>
                    </button>

                    <button *ngIf="canShowPass" mat-icon-button matSuffix (click)="hidePass = !hidePass" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePass">
                        <mat-icon>{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>

                </mat-form-field>



            </div>



            <button *ngIf="!isAuthed" mat-flat-button color="accent" class="sample-elements" style="width:90%; font-size: 2em; height: 50px;" (click)="login();">Sign In
            </button>

            <button *ngIf="isAuthed" mat-flat-button color="accent" class="sample-elements" style="width:90%; font-size: 2em; height: 50px;" (click)="logout();">Sign Out
            </button>

            <div style="width: 100%; height: 10px; font-size: 0.7em; font-weight: 100; text-align: center;">v2021.4 (C) 2021 Forced Potato</div>


        </mat-card-content>
    </mat-card>

</div>